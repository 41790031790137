@use "../../styles/variables" as *;

.modal {
  color: $black;
}

.modalHeader {
  border-bottom: 0;
}

.capitalize {
  text-transform: capitalize;
}

.amount {
  border: none;
  border-bottom: 1px solid $black;
  outline: none;
  font-size: 16px;
  width: 200px;
  font-size: 48px;
  margin-top: 30px;
  text-align: center;
}

.button {
  background-color: $red;
  margin-top: 30px;
  margin: 50px auto;
  display: block;
  border-color: $red;
  &:hover {
    background-color: $light-red;
    border-color: $light-red;
  }
}

.radio {
  input:checked {
    background-color: $red;
    border-color: $red;
  }
}