@use "../../styles/variables" as *;

.shareHeader {
  max-width: 750px;
}

.linkContainer {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.link {
  background-color: $yellow;
  border-radius: 15px;
  padding: 10px 20px;
  font-family: 'Audiowide', sans-serif;
  font-size: 20px;
  max-width: 100%;
  overflow: hidden;
}

.button {
  font-size: 20px;
  height: 50px;
  padding: 0 20px;
}

.matchChallengeBanner {
  color: $red;
  height: 80px;
  padding-top: 15px;
}

@media screen and (max-width: $mobile-width) {
  .link, .button {
    font-size: 16px;
  }
  .matchChallengeBanner {
    margin-bottom: 20px;
  }
}