@use "../../styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  h1{
    font-family: 'Audiowide', sans-serif;
    font-size: 60px;
    @media screen and (max-width: 500px) {
      font-size: 42px;
    }
  }
}

.description {
  font-family: 'Audiowide', sans-serif;
  font-size: 20px;
}

.moneyText {
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
  color: $yellow;
}

.tableHeader {
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  font-size: 40px;
  @media screen and (max-width: 500px) {
    font-size: 24px;
  }
}

.tableEntries {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;
  max-width: 100%;
}

.entry {
  height: 50px;
  background-color: rgba(209, 49, 52, 0.1);
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  align-items: center;
  font-size: 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  a {
    color: white;
    text-decoration: none;
  }
  td {
    padding: 0 40px;
    background-color: rgba(209, 49, 52, 0.1) !important;
  }
  td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  td:nth-child(2) {
    text-align: center;
  }
  td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }
  @media screen and (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
    td {
        padding: 0 8px;
    }
    td:first-child {
      padding-left: 15px;
      max-width: 150px;
    }
    
    td:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      padding-right: 15px;
    }
  }
}

.dates {
  display: flex;
  gap: 10px;
  font-size: 24px;
  font-family: 'Audiowide', sans-serif;
  line-height: 40px;
  flex-wrap: wrap;
  margin: 20px 0;
  .separator {
    font-size: 60px;
    font-family: Helvetica, sans-serif;
  }
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
  .active {
    font-size: 40px;
    text-decoration: none;
  }
  @media screen and (max-width: 500px) {
    font-size: 18px;
    .active {
      font-size: 24px;
    }
    .separator {
      font-size: 42px;
    }
  }
}

