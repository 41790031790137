@use "../../styles/variables" as *;

.playerItem {
  background-color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left !important;
  background-color: $black;
  color: #fff;
  font-size: 20px;
  padding: 5px 7px;
  line-height: normal;
  span {
    line-height: 22px;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 16px;
  }
}

.playerItem:hover {
  background-color: #ff8c8c;
}

.playerItem.selected {
  background-color: $red;
}

.playerInfo {
  display: flex;
  align-items: center;
}

.claimedPlayer {
  background-color: $black;
  color: #545454;
  &:hover {
    background-color: $black;
    cursor: default;
  }
}

.playerDetails {
  display: flex;
  transition: height 1s ease;
  height: 0;
  overflow: hidden;
  gap: 5px;
  margin-top: 5px;
  &.visible {
    height: auto;
  }
}

.headshotContainer {
  flex: 1;
  img {
    width: 100%;
    height: auto;
  }
}

.statsContainer {
  flex: 4;
}

.stats {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(3, auto);
  gap: 5px;
  text-align: right;
  @media screen and (max-width: $mobile-width) {
    gap: 2.5px;
  }
}

.statName {
  grid-column: 1;
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.statValue {
  grid-column: 2/3;
  display: flex;
  align-items: center;
}

.barContainer {
  border: 1px solid white;
  height: 12px;
  width: 100%;
  background-color: $black;
  overflow: hidden;
}

.bar {
  background-color: $green;
  height: 100%;
  width: 0;
}

.accolades {
  display: flex;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media screen and (max-width: $mobile-width) {
    gap: 2.5px;
  }
}

.accolade {
  font-family: 'Exo 2', sans-serif;
  background-color: #ECECEC;
  color: $black;
  padding: 5px;
  font-size: 12px;
  @media screen and (max-width: $mobile-width) {
    padding: 2.5px;
  }
}