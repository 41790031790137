@use "../../styles/variables" as *;

.header {
  font-family: 'Audiowide', sans-serif;
  font-size: 48px;
  margin: unset;
  @media screen and (max-width: $tablet-width) {
    font-size: 36px;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 24px;
  }
}