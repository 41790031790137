@use "../../styles/variables" as *;

.grid {
  display: grid;
}

.row {
  gap: 20px;
}

.section {
  background-color: $white;
  color: $black;
  text-align: left;
  padding: 40px;
  @media screen and (max-width: $mobile-width) {
    padding: 15px;
  }
}

.col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
}

.profileCol {
  flex: 0.5 0;
  @media screen and (max-width: 767px) {
    flex: 1 0;
  }
}

.sectionHeader {
  font-family: 'Audiowide', sans-serif;
  font-size: 40px;
}

.sectionSubHeader {
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
}

.red {
  color: $red;
}

.username {
  font-size: 40px;
  font-weight: bold;
}

.accountBalance {
  font-family: 'Audiowide', sans-serif;
  font-size: 80px;
  color: $red;
}

.button {
  background-color: $red;
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
  border-radius: 15px;
  border-color: $red;
  height: 50px;
  box-sizing: border-box;
  &:hover, &:active {
    background-color: $light-red !important;
    border-color: $light-red !important;
  }
}

.depositButton {
  @extend .button;
  background-color: $red;
  height: 90px;
  font-size: 40px;
  &:active {
    background-color: $light-red !important;
    border-color: $light-red !important;
  }
  @media screen and (max-width: 1400px) {
    font-size: 32px;
  }
  @media screen and (max-width: 1400px) {
    font-size: 24px;
  }
}

.link {
  color: $red;
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
  padding-left: 0;
  &:hover {
    color: $light-red;
  }
}

.buttonSecondary {
  @extend .button;
  background-color: #3B3B3B;
  border-color: #3B3B3B;
  margin: 10px 0;
  min-height: 50px;
  height: auto;
  &:hover, &:active {
    background-color: #3B3B3B !important;
    border-color: #3B3B3B !important;
  }
}

.editLink {
  font-size: 16px;
  color: $black;
  margin: 0;
  padding: 0;
  display: inline-block;
  width: fit-content;
  &:hover {
    color: $black;
  }
}

.settingsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

.match {
  margin-bottom: 20px;
  border-bottom: 1px solid #3B3B3B;
  padding-bottom: 20px;
  font-family: 'Audiowide', sans-serif;
  &:last-child {
    margin-bottom: 0;
    border-bottom: unset;
    padding-bottom: unset;
  }
}