.score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-weight: bold;
}

.canvas {
  position: "fixed";
  pointer-events: "none";
  width: "100%";
  height: "100%";
  top: 0;
  left: 0;
}

.lb-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lb-input {
  max-width: 400px;
  width: 100%;
  text-align: center;
  padding: 10px;
  margin: 30px;
  box-sizing: border-box;
}

.content-body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  padding-top: 0;
}

.content {
  /* max-width: 1100px; */
  width: 100%;
  text-align: center;
  padding: 20px;
  margin-top: 0;
  padding-top: 0;
  text-wrap: balance;
}