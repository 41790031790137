@use "../../styles/variables" as *;

.button {
  width: 300px;
}

.centerText {
  text-align: center;
}

.blackText {
  color: $black;
}