ul {
  list-style-type: none; /* Removes bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

html {
  height: 100%;
  font-family: 'Manrope-VariableFont_wght';
  background-color: #0f0f0f;
  color: #FFFFFF;
}

body { 
  min-height: 100vh;
  font-family: 'Manrope-VariableFont_wght' !important;
  background-color: #0f0f0f !important;
  color: #FFFFFF !important;
 }

.App {
  text-align: center;
  min-height: 100vh;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  @media screen and (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

section {
  width: 100%;
  margin: 10px 10px;
}

/* styles.css */
.player-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; 
  align-items: center;
  justify-content: center;
  margin-left: 20px;;
}

.player-card {
  border: 2px solid #333;
  padding: 10px;
  width: 300px;
  background-color: #000;
  box-shadow: 0px 0px 5px #666;
  cursor: pointer;
  transition: background-color 0.3s; /* Smooth transition for background color change */
}

.player-card.selected {
  background-color: lightgreen; /* Change the background color to green when selected */
}

.player-card h3 {
  font-size: 1.2rem;
  margin: 0;
}

.player-card p {
  margin: 5px 0;
}

.player-card strong {
  font-weight: bold;
}

/* List container */
.player-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 0;
  margin: 0;
  padding: 0px 5px;
}

/* Name and price container */
.player-name-price {
  display: flex;
  align-items: center;
  gap: 10px;
}

.player-name {
  font-size: 10px;
  font-weight: bold;
}

.player-price {
  font-size: 8px;
  color: #555;
}
/* styles.css */
.submit-button {
  background-color: #0074d9;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.signup-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.deposit-input input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.deposit-option {
  cursor: pointer;
  margin: 5px;
}

 /*TOPBAR STYLES */
.brand-name-container {
  display: flex;
  justify-content: flex-start;
  margin: 0px !important;
  padding: 0px !important;
  cursor: pointer;
  width: 194px;
  max-width: 100%;
  svg {
    width: 100%;
    height: auto;
  }
}

.user-profile {
  display: flex;
  align-items: center;
}

.profile-container {
  font-size: 25px;
  height: 100%;
}

.home-section {
  margin-bottom: 20px;
  text-align: left;
}

.main-content {
  margin-top: 30px;
}

@media only screen and (max-width: 900px) {
  .navbar-brand-name {
    height: 30px;
  }
}

@media only screen and (min-width: 901px) {
  .App {
    max-width: 1300px;
    margin: 0 auto;
  }
}

/* AUTH STYLE */
.auth-header {
  display: flex;
  height: 150px;
  width: calc(100% + 60px);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: -30px;
  margin-right: -30px; 
  margin-top: -30px
}

.auth-header-modal {
  display: flex;
  height: 150px;
  width: calc(100%);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 0;
 }

.auth-header-no-margin {
  display: flex;
  height: 150px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -16px

}

.auth-body {
  margin-top: 50px;
}

.auth-input {
  max-width: 500px !important;
  width: 90% !important;
  background-color: #f2f2f2 !important;
  border: none !important;
  border-radius: 0% !important;
  height: 50px;
}

.auth-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9e9e9e !important;
  opacity: 1 !important; /* Firefox */
}

.auth-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #9e9e9e !important;
}

.auth-input::-ms-input-placeholder { /* Microsoft Edge */
  color: #9e9e9e !important;
}

.auth-from-row {
  justify-content: center;
  margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
  .App {
    padding-left: 15px;
    padding-right: 15px;
  }

  .auth-header {
    margin-left: -15px;
    margin-right: -15px;
  }
}



/* PLAY STYLES */
.lineup-position {
  font-size: 24px;
  font-family: 'BebasNeue-Regular';
}

.lineup-position-info {
  font-size: 16px;
  text-align: left;;
}

.result-text {
  font-size: 24px;
  font-weight: bold;
}

.score-text {
  font-size: 62px;
  color: #FF5050;
  font-family: 'BebasNeue-Regular';
}

.ellipsis-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
}

.content-header {
  padding-top: 30px;
}

/* Profile Styles */
.signout-button {
  background-color: #FF5050 !important;
  margin-top: 100px;
  margin-bottom: 100px;
}

.profile-section {
  padding-top: 30px;
  text-align: left;
}

.manrope-bold-40 {
  font-weight: bold;
  font-size: 40px;
}


.nav-link {
  color: #FF5050 !important;
}


@keyframes balanceIncrease {
  0% { color: inherit; }
  50% { color: green; }
  50% { font-size: 24px; }
  100% { font-size: 20px; }
  100% { color: inherit; }
}

.balance-increase {
  animation: balanceIncrease 2s;
}

@keyframes balanceDecrease {
  0% { color: inherit; }
  50% { color: red; }
  50% { font-size: 24px; }
  100% { font-size: 20px; }
  100% { color: inherit; }
}

.balance-decrease {
  animation: balanceDecrease 2s;
}

.success-toast {
  position: absolute !important;
  top: 20%;
  right: 38%;
  background-color: #5cb85c !important;
  color: white !important;
}

.footer {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.social-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.footer-promo {
  font-size: 14px;
  font-family: 'BebasNeue-Regular';
  text-align: center;
  margin-bottom: -20px;
  margin-top: 50px;
}