@use "../../styles/variables" as *;

.container {
  text-align: left;
}

.subHeader {
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
  margin: 0;
}

.description {
  font-family: 'Audiowide', sans-serif;
  font-size: 20px;
}

.moneyText {
  font-family: 'Audiowide', sans-serif;
  font-size: 24px;
  color: $yellow;
}

.link {
  color: $white;
  margin-left: 20px;
}

.button {
  margin-top: 20px;
}