@use '../../styles/variables' as *;

.button {
  position: relative;
  color: #FFFFFF;
  background-color: $red;
  display: inline-flex;
  font-family: 'Audiowide', sans-serif;
  font-size: 30px;
  line-height: normal;
  text-align: center;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 15px;
  height: 66px;
  align-items: center;
  white-space: nowrap;
  max-width: 100%;
  justify-content: center;
  &:active {
    background-color: $light-red;
    
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 20px;
    padding: 10px;
    height: 50px;
    border-radius: 10px;
  }
}

.buttonFixed {
  position: fixed;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
}

// .pointedButtonFixed:before {
//   content:"";
//   position: absolute;
//   right: 100%;
//   top:0px;
//   width:0px;
//   height:0px;
//   border-top:20px solid transparent;
//   border-right:10px solid #FF5050;
//   border-bottom:20px solid transparent;
// }

// .pointedButtonFixed:after {
//   content:"";
//   position: absolute;
//   left: 100%;
//   top:0px;
//   width:0px;
//   height:0px;
//   border-top:20px solid transparent;
//   border-left:10px solid #FF5050;
//   border-bottom:20px solid transparent;
// }

/* .pointedButton:before {
  content:"";
  position: absolute;
  right: 100%;
  top:0px;
  width:0px;
  height:0px;
  border-top:20px solid transparent;
  border-right:10px solid #FF5050;
  border-bottom:20px solid transparent;
} */

/* .pointedButton:after {
  content:"";
  position: absolute;
  left: 100%;
  top:0px;
  width:0px;
  height:0px;
  border-top:20px solid transparent;
  border-left:10px solid #FF5050;
  border-bottom:20px solid transparent;
}  */




