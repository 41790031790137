@use "../../styles/variables" as *;

.container {
  text-align: left;
}

.subHeader {
  font-size: 30px;
  font-family: 'Audiowide', sans-serif;
  margin-top: 1em;
  margin-bottom: 2em;
  @media screen and (max-width: $mobile-width) {
    font-size: 20px;
  }
}

.colName {
  font-family: 'Audiowide', sans-serif;
  font-size: 40px;
  @media screen and (max-width: $tablet-width) {
    font-size: 30px;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 20px;
  }
}

.row {
  &:hover {
    background-color: $red;
  }
  background-color: rgba(209, 49, 52, 0.2);
  border-radius: 15px;
  display: flex;
  align-items: center;
  min-height: 50px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  @media screen and (max-width: $mobile-width) {
    font-size: 16px;
  }
}

.matches {
  display: flex;
  flex-direction: column;
  gap: 10px;
}