@use '../../../styles/variables' as *;

.header {
  color: $red;
  font-family: 'Audiowide', sans-serif;
  font-size: 60px;
}

.green {
  color: $green;
}

.username {
  font-family: 'Audiowide', sans-serif;
  font-size: 32px;

  //ellipsis overflow
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.position {
  font-family: 'Audiowide', sans-serif;
  font-size: 16px;
  color: $red;
}

.contentSection {
  margin-bottom: 40px;
}

.contentHeader {
  font-family: 'Audiowide', sans-serif;
  font-size: 32px;
  margin-bottom: 10px;
}