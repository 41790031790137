@use "../../styles/variables" as *;

.container {
  width: 40px;
  font-family: 'Audiowide', sans-serif;
  margin-right: 10px;
  display: flex;

  @media screen and (max-width: $mobile-width) {
    margin-right: 2px;
  }
}

.goldCoinContainer {
  display: flex;
  align-items: center;
}

.goldCoin {
  width: 18.42px;
  height: 18.42px;
  cursor: pointer;
  margin-right: 2px;
}

.priceContainer {
  width: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $mobile-width) {
    width: 15px;
  }
}

.price {
  text-align: center;
}