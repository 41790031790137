@use "../../styles/variables" as *;

.container {
  margin: 1em 0 3em 0;
}

.playerCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.positionCol {
  width: 235px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 540px) {
    min-width: 145px;
    max-width: 48%;
  }
}

.playerItem {
  cursor: default;
  flex: 1;
}