@use "../../styles/_variables.scss" as *;

.playContainer {
  position: relative;
  text-align: left;
  line-height: 0;
}

.gameRulesFixed {
  margin: 0 auto;
  text-align: center;
  margin-top: 50px;
}

.gameRulesFixedPayout {
  margin: 1em 0;
  font-family: 'Audiowide', sans-serif;
  font-size: 40px;
  line-height: normal;
  span {
    color: $yellow;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 24px;
  }
}

.betContainer {
  margin-top: 80px;
}

.matchBetOptions {
  display: flex;
  gap: 20px;
  margin: 20px 0;
  @media screen and (max-width: $mobile-width) {
    gap: 10px;
  }
}

.matchBetOption {
  cursor: pointer;
  background-color: rgb(33, 37, 41) !important;
  height: 66px;
  display: flex;
  align-items: center;
  font-family: 'Audiowide', sans-serif;
  font-size: 40px;
  border-radius: 15px;
  color: #ffffff;
  &.selected {
    background-color: $yellow !important;
  }

  @media screen and (max-width: $tablet-width) {
    font-size: 30px;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 24px;
    height: 40px;
    padding: 10px;
    border-radius: 10px;
  }
}

.buttons {
  text-align: center;
  @media screen and (max-width: $tablet-width) {
    .button {
      white-space: wrap;
      height: auto;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      text-align: center;
    }
  }
}

.orText {
  font-size: 36px;
  font-family: 'Audiowide', sans-serif;
  line-height: normal;
  margin: 10px 0 0 0;
}

.link {
  color: $red;
  font-family: 'Audiowide', sans-serif;
  &:hover, &:active {
    color: $light-red !important;
  }
}