@use "../../../styles/_variables.scss" as *;

.container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 140px);
  overflow: hidden;
}

.headerContainer {
  font-family: 'Audiowide', sans-serif;
  font-size: 20px;
}

.playerPositionContainer {
  margin-top: 20px;
  padding-bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  flex: 1;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
  }
}

.browseButton {
  font-size: 20px;
  height: 44px;
  border-radius: 10px;
  padding: 0 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 30px;
  @media screen and (max-width: $mobile-width) {
    margin: 5px;
  }
}

.remainingBudgetContainer {
  font-family: 'Audiowide', sans-serif;
  display: flex;
  align-items: center;
}

.remainingBudget {
  font-size: 40px;
  @media screen and (max-width: $tablet-width) {
    font-size: 32px;
  }
  @media screen and (max-width: $mobile-width) {
    font-size: 20px;
  }
}

.price {
  width: auto;
}

.goldCoin {
  width: 39px;
  height: 39px;
  margin: 0 10px;
}