@use '../../styles/variables' as *;

.positionHeader {
  font-size: 40px;
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  color: $red;
  @media screen and (max-width: $mobile-width) {
    font-size: 24px;
  }
}